import React, { useEffect } from "react";
import {
  ActivityIndicator,
  Image,
  ImageBackground,
  Modal,
  StyleSheet,
  TouchableOpacity,
  View,
} from "react-native";
import ButtonLinear from "../../components/Buttons/ButtonLinear";
import HeaderButton from "../../components/HeaderButton";
import InputApp from "../../components/InputApp";
import ModalSelect from "../../components/ModalSelect";
import RequireIndicator from "../../components/RequireIndicator";
import Text from "../../components/Text";
import TagItem from "../../components/WorkProfile/TagItem";
import ImgLoadAndCrop from "../../components/imgLoadAndCrop";
import { Colors } from "../../configs";
import { SOURCE_ICON } from "../../images";
import iconSearch from "../../images/ic_search_normal@3x.png";
import locales_es from "../../locales/es";
import Theme from "../../style/Theme";
import useAddMedicalLicense from "./shared/useAddMedicalLicense";

interface AddMedicalLicenseProps {}

const AddMedicalLicense = (props: AddMedicalLicenseProps) => {
  const {
    onSubmit,
    sisa,
    medicalLicenseConfiguration,
    renderAdditionalField,
    searchSpecialtiesResults,
    setSearchSpecialtiesResults,
    cropping,
    errors,
    theme,
    onPressSearchResult,
    onPressSpecialtyX,
    onSearchSpecialtiesChange,
    onBlur,
    setErrors,
    setCropping,
    profession,
    setProfession,
    medicalLicenseType,
    setMedicalLicenseType,
    licenseImage,
    setLicenseImage,
    renderListItem,
    specialties,
    searchSpecialties,
    setSearchSpecialties,
    visibleAdditionalFieldMenuOption,
    closeAdditionalFieldMenuOption,
    onChangeInput,
    additionalFieldsOptions,
    imgLoadAndCrop,
    openCrop,
    uploadImage,
    editSpecialities,
    editProfession,
    editImage,
  } = useAddMedicalLicense();



  return (
    <>
      <View style={styles.container}>
        <View style={styles.content}>
          <View style={styles.headerWrapper}>
            <HeaderButton />
            <Text size={32} lineHeight={36} bold>
              {locales_es.workProfile.title}
            </Text>
            <Text
              size={16}
              lineHeight={18}
              marginTop={8}
              style={{ maxWidth: "100%" }}
            >
              Completa estos datos para registrar tu Matrícula. Son datos
              mínimos necesarios.
            </Text>
          </View>
        </View>
        <View style={styles.pageContent}>
          <View style={styles.backgroundImageWrapper}>
            <Image source={SOURCE_ICON.rx} style={styles.backgroundImage} />
          </View>
          <View style={styles.formContainer}>
            <View style={styles.form}>
              <View style={[styles.formRow, styles.firstInfo]}>
                <View style={styles.flexGrow}>
                  <InputApp
                    title={"Profesión"}
                    placeholder={"Médico Cirujano"}
                    value={profession}
                    onChangeText={setProfession}
                    borderColor={
                      errors.includes("profession")
                        ? theme.lineErrorColor
                        : theme.innearColor
                    }
                    onBlur={() => onBlur("profession")}
                    blurOnSubmit={true}
                    showRequired={true}
                    editable={editProfession}
                  />
                </View>
                <View style={{ ...styles.flexGrow, marginTop: 15 }}>
                  <InputApp
                    title={"Especialidades"}
                    placeholder="Busque o escriba sus especialidades..."
                    value={searchSpecialties}
                    onChange={onSearchSpecialtiesChange}
                    borderColor={theme.innearColor}
                    isShowIconLeft
                    iconLeft={
                      <Image source={iconSearch} style={styles.iconSearch} />
                    }
                    autoCorrect={false}
                    autoCapitalize="words"
                    onBlur={() => onBlur("searchSpecialties")}
                    showRequired={false}
                    editable={editSpecialities}
                  />
                  <View
                    style={[
                      { backgroundColor: "white" },
                      searchSpecialtiesResults &&
                      searchSpecialtiesResults.length
                        ? {
                            borderColor: theme.innearColor,
                            borderWidth: 1,
                            borderRadius: 8,
                            position: "absolute",
                            zIndex: 2000,
                            top: 75,
                            left: 0,
                            width: "100%",
                            height: 100,
                            overflow: "auto",
                          }
                        : null,
                    ]}
                  >
                    {searchSpecialtiesResults && searchSpecialtiesResults.length
                      ? searchSpecialtiesResults.map((result: any) =>
                          renderListItem(result.name)
                        )
                      : null}
                  </View>
                  <View style={styles.spec}>
                    {specialties && specialties.length
                      ? specialties.map((specialty: any, index: number) => (
                          <TagItem
                            iconColor={{ tintColor: "white" }}
                            title={specialty}
                            key={index.toString()}
                            onPressX={() => onPressSpecialtyX(index)}
                          />
                        ))
                      : null}
                  </View>
                </View>
              </View>
            </View>
            <View>
              <View>
                <Text
                  size={18}
                  lineHeight={18}
                  marginTop={25}
                  marginBottom={25}
                  bold
                  left
                >
                  Matrícula
                </Text>
              </View>
              <View style={styles.flexGrow}>
                {medicalLicenseConfiguration === null ? (
                  <ActivityIndicator />
                ) : medicalLicenseConfiguration &&
                  medicalLicenseConfiguration.length ? (
                  medicalLicenseConfiguration.map(
                    (additionalField: any, index: number) =>
                      renderAdditionalField(additionalField)
                  )
                ) : null}
              </View>
            </View>
            <View style={{ flexDirection: "row" }}>
              <Text
                size={18}
                lineHeight={0}
                marginTop={15}
                marginBottom={0}
                bold
                left
              >
                Foto de la Matrícula
                <Text
                  size={18}
                  lineHeight={0}
                  marginTop={15}
                  marginBottom={0}
                  marginLeft={10}
                  bold
                  left
                >
                  {editImage && <RequireIndicator />}
                </Text>
              </Text>
            </View>
            <View
              style={{
                justifyContent: "left",
                alignItems: "left",
                zIndex: 1000,
              }}
            >
              <ImgLoadAndCrop
                ref={imgLoadAndCrop}
                silentMode={true}
                image={licenseImage}
                inputLabel={"Imagen"}
                imageId={"logoImage"}
                imageState={"logoImage"}
                inputWrapperCustomClassName="col-12 pl-md-0"
                canvasCustomClassName="profile"
                saveCrop={uploadImage}
                deleteCrop={() => console.log("deleteCrop")}
                deletedImage={() => console.log("deleteImage")}
                onCropping={() => setCropping(true)}
                onStopCropping={() => setCropping(false)}
              />
              {!cropping && (
                <View style={{ flex: 1 }}>
                  <View
                    style={{
                      justifyContent: "left",
                      width: "50%",
                      zIndex: 1000,
                      marginTop: "-20px",
                    }}
                  >



                    {licenseImage === null ? (
                      <Text size={16} lineHeight={22} marginTop={16}>
                        Es necesario subir una imagen
                        <ActivityIndicator />
                      </Text>
                    ) : licenseImage ? (
                      <View
                        style={{
                          width: "50%",
                          justifyContent: "left",
                          zIndex: 1000,
                        }}
                      >
                        <TouchableOpacity
                          onPress={() => openCrop()}
                          style={styles.licenseImage}
                        >
                         <Image
                            source={{ uri: licenseImage.uri }}
                            style={{ width: 200, height: 200 }}
                            onError={(e) => console.log("Error cargando imagen:", e.nativeEvent)}
                          />
                        </TouchableOpacity>
                      </View>
                    ) : (
                      <TouchableOpacity
                        style={styles.imgUpload}
                        onPress={() => openCrop()}
                      >
                        <View style={{ alignItems: "left" }}>
                          <Image
                            source={{
                              uri: SOURCE_ICON.photoLibrary,
                              width: 50,
                              height: 50,
                            }}
                            style={{ tintColor: "grey" }}
                          />
                          <Text size="20" lineHeight={10}>
                            SUBIR IMAGEN{" "}
                          </Text>
                        </View>
                      </TouchableOpacity>
                    )}
                  </View>
                  {editImage && (
                    <Text size={16} lineHeight={22} marginTop={16}>
                      Requerimos una foto para poder validar la misma.
                    </Text>
                  )}
                  {errors.includes("licenseImage") && (
                    <Text
                      size={16}
                      lineHeight={22}
                      color={Colors.Red}
                      marginTop={16}
                    >
                      La imagen es requerida.
                    </Text>
                  )}
                </View>
              )}
            </View>
            <View
              style={{
                justifyContent: "left",
                alignItems: "left",
                width: 800,
                marginTop: 25,
              }}
            >
              <ButtonLinear
                white
                title={"Guardar"}
                style={styles.buttonLinear}
                onPress={() => onSubmit()}
              />
            </View>
          </View>
        </View>
        {visibleAdditionalFieldMenuOption && (
          <Modal
            visible={true}
            onRequestClose={closeAdditionalFieldMenuOption}
            transparent
            animationType={"fade"}
          >
            <ModalSelect
              onPressItem={(item) => {
                onChangeInput(item.value, item);
              }}
              choices={additionalFieldsOptions}
              close={closeAdditionalFieldMenuOption}
              style={{
                width: "60%",
                marginLeft: "auto",
                marginRight: "auto",
                marginBottom: 0,
              }}
              lineHeight={2}
            />
          </Modal>
        )}
      </View>
    </>
  );
};

const styles = StyleSheet.create({
  buttonChildren: {
    ...Theme.icons,
    marginLeft: 8,
  },
  buttonLinear: {
    marginTop: 32,
    color: Colors.White,
    width: "30vh",
  },
  imgContainer: { width: 500, marginTop: 0 },
  avatarStyles: { width: 150, height: 150 },
  container: {},
  content: {
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
  },
  headerWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "100%",
    marginTop: 32,
  },
  pageContent: {
    flexDirection: "row-reverse",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginTop: 32,
  },
  backgroundImageWrapper: {
    width: "40%",
    minWidth: 400,
    alignItems: "flex-start",
    marginBottom: 32,
  },
  backgroundImage: { width: 420, height: 380 },
  formContainer: { flex: 1, minWidth: 400 },
  form: { marginTop: 24 },
  prefixField: { width: 200 },
  prefixSelector: {
    padding: 0,
    marginTop: 0,
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    gap: 16,
  },
  cta: { width: 200 },
  flexGrow: { flex: 1, marginBottom: 30 },
  iconSearch: {
    ...Theme.icons,
    tintColor: Colors.DodgerBlue,
  },
  spec: {
    marginTop: 8,
    ...Theme.flexRow,
    flexWrap: "wrap",
  },
  specLanguage: {
    marginTop: 8,
    ...Theme.flexRow,
    flexWrap: "wrap",
    paddingBottom: 52,
  },
  imgUpload: {
    border: "2px dashed grey",
    borderRadius: 16,
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 200,
  },
  image: {
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  licenseImage: {
    marginTop: 4,
    minHeight: 150,
    borderRadius: 8,
    backgroundColor: "#fff",
  },
  firstInfo: {
    paddingBottom: 24,
    borderBottomWidth: 1,
    borderColor: "#e8e8eb",
    marginBottom: 35,
  },
});

export default AddMedicalLicense;
