import React, {
  memo,
  useCallback,
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
} from 'react';
import {View, StyleSheet, Image, Modal} from 'react-native';
import Text from '../../components/Text';
import Theme from '../../style/Theme';
import scale from '../../utils/scale';
import {Routes} from '../../configs';
import ButtonLinear from '../../components/Buttons/ButtonLinear';
import iconNext from '../../images/ic_next.png';
import Container from '../../components/Layout/Container';
import HeaderButton from '../../components/HeaderButton';
import {getBottomSpace} from 'react-native-iphone-x-helper';
import InputApp from '../../components/InputApp';
import ModalSelect from '../../components/ModalSelect';
import {useTheme} from '../../configs/Theme';
import ButtonChangeCategory from '../../components/ButtonChangeCategory';
import {categoryList} from '../../type/category';
import ModalSlideBottom from '../../components/ModalSlideBottom';
import ModalSearchStrings from '../../components/ModalSearchStrings';
import Content from '../../components/Layout/Content';
import TranslationService from '../../locales';
import {
  FAKE_EMAIL_REGEX,
} from '../../models/constants';

// TODO KEEP IMPROVING THIS. SAY NO TO COPYNG & PASTING
import {api, auth, helpers, appHelpers, dateTimeService} from '../../import';
import {
  EVENT_RELOAD_PATIENT,
  EVENT_SHOW_MODAL_INFO,
} from '../../constants/Events';
import locales_es from '../../locales/es';
import {showModalInfo} from '../../modules/ViewHelpers';
import FullScreenLoading from '../../components/fullscreenLoading';
import {CommonStyles} from '../../styles/CommonStyles';

import ModalChangePhoneCode from '../../components/SignUp/ModalChangePhoneCode';
import {TcodeArea} from '../../type/codeArea';
import {
  validateEmptyFields,
} from '../../utils/validation/forms';
import Layout from '../../components/Layout/Layout';
import {useHistory} from "react-router-dom";
import useModalWithKeyboard from "../../hooks/useModalWithKeyboard";
import {EventRegister} from "../../modules/EventRegister";
import { getStatusBarHeight } from "react-native-iphone-x-helper";

interface PatientMedicalInsuranceProps {
}

const PatientMedicalInsurance = memo((props: PatientMedicalInsuranceProps) => {
  const history = useHistory();
  const params = history.location.state;
  const [errors, setErrors] = useState([]);
  const [load, setLoad] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState(false);

  const translationService = new TranslationService();
  const [translations, setTranslations] = useState(null);

  const [patientId, setPatientId] = useState(
    params && params.patientId ? params.patientId : null,
  );

  const [externalIdParam] = useState(
    params && params.externalIdParam ? params.externalIdParam : null,
  );

  const prescriptionData = params && params.prescriptionData ? params.prescriptionData : null;

  const [patientData, setPatientData] = useState(
    params && params.patientData ? params.patientData : null,
  );
  const [countryId, setCountryId] = useState(null); // null by now. Maybe 1 for "Argentina"?
  const [profileImage, setProfileImage] = useState('');
  const [images, setImages] = useState([]);

  const historyBackSteps = params && params.historyBackSteps ? params.historyBackSteps : null;
  const screenTitle = params && params.screenTitle ? params.screenTitle : null;

  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [identification, setIdentification] = useState('');

  const [gendersList, setGendersList] = useState(null);

  const [identificationType, setIdentificationType] = useState(null);
  const [identificationTypeId, setIdentificationTypeId] = useState(null);
  const [identificationTypesList, setIdentificationTypesList] = useState(null);
  const [date, setDate] = useState(null);
  const [birthDate, setBirthDate] = useState(null);
  const [countries, setCountries] = useState([]);
  const [codeArea, setCodeArea] = useState(null);

  const [email, setEmail] = useState('');
  const [medicalInsuranceList, setMedicalInsuranceList] = useState(null);
  const [medicalInsurance, setMedicalInsurance] = useState(null);
  const [medicalInsurancePlan, setMedicalInsurancePlan] = useState('');
  const [medicalInsuranceNumber, setMedicalInsuranceNumber] = useState('');
  const [medicalInsuranceId, setMedicalInsuranceId] = useState('');
  const [patientMedicalInsuranceId, setPatientMedicalInsuranceId] = useState('');
  const [medicalInsuranceDniTitular, setMedicalInsuranceDniTitular] = useState('');
  const [comment, setComment] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [gender, setGender] = useState<{
    id?: number | null;
    name?: string | null;
    code?: string | null;
    icon?: any;
  }>({id: 3, title: null, icon: null});

  // External Patients
  const [externalId, setExternalId] = useState(null);
  const [clinicIdParam] = useState(
    params && params.clinicIdParam ? params.clinicIdParam : null,
  );

  const {theme} = useTheme();
  const inputRef: any = useRef();

  const onChangeMedicalInsurance = useCallback((item: categoryList) => {
    setMedicalInsurance(item);
    closeMedicalInsuranceModal();
  }, []);

  const onSend = () => {
    const errors = validateEmptyFields({
      medicalInsuranceNumber
    });

    const identificationValidation = appHelpers.validateIdentification(
      countryId,
      identification,
    );
    if (identificationValidation !== true) {
      showModalInfo(
        identificationValidation.title,
        identificationValidation.message,
      );
      return;
    }

    if (
      medicalInsurance &&
      medicalInsurance.is_none !== undefined &&
      medicalInsurance.is_none === false &&
      !medicalInsuranceNumber
    ) {
      errors.push('medicalInsuranceNumber');
      setErrors(errors);
      showModalInfo(
        locales_es.infoModal.title,
        'Debe ingresar el número de afiliado del paciente, o seleccionar que no tiene cobertura',
      );
      return;
    }

    if (errors.length) {
      setErrors(errors);
      showModalInfo(
        locales_es.infoModal.title,
        locales_es.errorModal.checkData,
      );
    } else {
      setLoading(true);
      setLoadingText('Actualizando datos...');
      const objData = {
        medical_insurance_id: medicalInsuranceId,
        medical_insurance_plan: medicalInsurancePlan,
        medical_insurance_number: medicalInsuranceNumber,
        medical_insurance_dni_titular: medicalInsuranceDniTitular,
        medical_insurance_selected: 1,
      };
      api
        .putPatientMedicalInsurance(patientId, patientMedicalInsuranceId, objData)
        .then((res: any) => {
          EventRegister.emit(EVENT_RELOAD_PATIENT, {});
          goToNextStep(res);
        })
        .catch((err: any) => {
          showModalInfo(
            locales_es.errorModal.title,
            helpers.getErrorMsg(err),
          );
          setLoading(false);
        });
    }
  };

  const goToNextStep = res => {
    setLoading(false);
    setLoadingText('');
    /*navigate(Routes.PatientProfile, {
      patientId: res.data.id,
      historyBackSteps,
    });*/
    showModalInfo(
      locales_es.infoModal.title,
      'Intente generar nuevamente la receta con los datos ahora corregidos.',
    );
    if (historyBackSteps) {
      /*const popAction = StackActions.pop(historyBackSteps);
      props.navigation.dispatch(popAction);*/
      history.push(`/${Routes.AddPrescription}`, {patientId: patientId, prevPrescriptionData: prescriptionData, historyBackSteps})
    } else {
      history.goBack();
    }
    // Replace stack current screen with next screen
    /*props.navigation.dispatch(
      StackActions.replace(Routes.PatientProfile, {
        patientId: res.data.id,
        historyBackSteps,
      }),
    );*/
  };

  const {
    open: openMedicalInsuranceModal,
    close: closeMedicalInsuranceModal,
    visible: visibleMedicalInsuranceModal,
    transY: transYMedicalInsuranceModal,
  } = useModalWithKeyboard();

  const {
    visible: visibleMenuOption,
    open: openMenuOption,
    close: closeMenuOption,
  } = useModalWithKeyboard();

  const {
    visible: visibleCountries,
    open: openCountries,
    close: closeCountries,
    transY: transYCountries,
  } = useModalWithKeyboard();

  const handleSelectIdentificationTypeMenuOption = React.useCallback(
    item => {
      setIdentificationType(item.name);
      setIdentificationTypeId(item.id);
      closeMenuOption();
    },
    [closeMenuOption, history],
  );

  const onChangeCode = (item: TcodeArea) => {
    setCodeArea(item);
    closeCountries();
  };

  const medicalInsuranceNumberRef = React.createRef();

  useEffect(() => {
    if (countryId) {
      api
        .getIdentificationTypesByCountryId(countryId)
        .then((res: any) => {
          setIdentificationTypesList(res.data);
          if (!patientId) {
            handleSelectIdentificationTypeMenuOption(res.data[0]);
          }
        })
        .catch((err: any) => {
          showModalInfo(
            locales_es.errorModal.title,
            helpers.getErrorMsg(err.message),
          );
        });
      api
        .getCountries()
        .then((res: any) => {
          setCountries(res.data);
          if (!patientId) {
            // setCodeArea(res.data[0]);
            setCodeArea(
              res.data.filter((country: any) => country.id === countryId)[0],
            );
          }
        })
        .catch((err: any) => {
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.errorModal.title,
            message: helpers.getErrorMsg(err),
          });
        });
      api
        .getMedicalInsurancesByCountryId(countryId)
        .then((res: any) => {
          const insurancesList = res.data;

          setMedicalInsuranceList(insurancesList);
          if (patientData && patientData.medical_insurance_id) {
            const insurance = insurancesList.filter(
              (i: any) =>
                Number(i.id) === Number(patientData.medical_insurance_id),
            )[0];
            setMedicalInsurance(insurance);
          } else if (externalIdParam && patientData.medical_insurance) {
            setMedicalInsurance(patientData.medical_insurance || null);
          } else if (!patientId) {
            setMedicalInsurance(res.data[0]);
          }
        })
        .catch((err: any) => {
          EventRegister.emit(EVENT_SHOW_MODAL_INFO, {
            title: locales_es.errorModal.title,
            message: helpers.getErrorMsg(err),
          });
        });
    }

    translationService.translate().then(translation => {
      setTranslations(translation);
    });
  }, [countryId]);
  // COMPONENT-DID-MOUNT AND LOAD()
  useEffect(() => {

    auth
      .getLocalUserData()
      .then((res: any) => {
        setCountryId(res.user.country_id);
      })
      .catch((err: any) => {
        helpers.processError(err);
      });

    api
      .getGenders()
      .then((res: any) => {
        setGendersList(res.data);
    })
    .catch((err: any) => {
      showModalInfo(
        locales_es.errorModal.title,
        helpers.getErrorMsg(err.message),
      );
    });

    if (patientData) {
      setName(patientData && patientData.name ? patientData.name : '');
      setLastname(
        patientData && patientData.lastname ? patientData.lastname : '',
      );
      setMedicalInsurancePlan(
        patientData && patientData.medical_insurance_plan
          ? patientData.medical_insurance_plan
          : '',
      );
      setMedicalInsuranceNumber(
        patientData && patientData.medical_insurance_number
          ? patientData.medical_insurance_number
          : '',
      );
      if (externalIdParam) {
        setIdentification(
          patientData && patientData.identification
            ? patientData.identification
            : '',
        );
        setGender({id: patientData.gender_id});
        setExternalId(patientData.external_id);
      }
    }

    if (patientId) {
      setLoading(true);
      setLoadingText('Cargando paciente...');
      api
        .getPatient(patientId)
        .then((res: any) => {
          // setUserData(res.data);
          const userData = res.data;
          if (userData) {
            setUserData(userData);
          }
          setLoading(false);
          setLoadingText('');
          api
            .getPatientMedicalInsurances(patientId)
            .then((resPatient: any) => {
              const selected = resPatient.data.find((insurance: any) => insurance.medical_insurance_selected === 1);
              if (selected) {
                setMedicalInsurancePlan(selected.medical_insurance_plan);
                setMedicalInsuranceNumber(selected.medical_insurance_number);
                setMedicalInsuranceId(selected.medical_insurance_id);
                setMedicalInsuranceDniTitular(selected.medical_insurance_dni_titular);
                setMedicalInsurance(selected.medical_insurance);
                setPatientMedicalInsuranceId(selected.id);
              }
            }).catch((err) => {
              console.log({err});
            })
      })
      .catch((err: any) => {
        helpers.processError(err);
        setLoading(false);
        setLoadingText('');
      });

      api
        .getPatientImages(patientId)
        .then((res: any) => {
          setImages(res.data);
      })
      .catch((err: any) => {
        helpers.processError(err);
      });
    }
  }, [load]);

  const setUserData = (userData: any) => {
    setProfileImage({uri: userData.full_profile_image});
    setName(userData.name);
    setLastname(userData.lastname);
    setIdentificationTypeId(userData.identification_type.id); // WARN: duplicated id and name
    setIdentificationType(userData.identification_type.name); // WARN: duplicated id and name
    setIdentification(userData.identification);
    setGender({id: userData.gender_id});
    const parsedDate = dateTimeService.parseAPIStringToDate(
      userData.birth_date,
    );
    setDate(parsedDate);
    setBirthDate(userData.birth_date);
    setCodeArea(userData.country);
    setPhoneNumber(userData.cell_phone);
    setEmail(FAKE_EMAIL_REGEX.test(userData.email) ? '' : userData.email);
    setMedicalInsurance(userData.medical_insurance);
    setMedicalInsurancePlan(userData.medical_insurance_plan);
    setMedicalInsuranceNumber(userData.medical_insurance_number);
    setComment(userData.comment);
  };

  return (
    <>
      <View style={styles.container}>
        <View style={styles.content}>

        <View style={styles.headerWrapper}>
          <HeaderButton onPress={
              patientData && !externalIdParam
                ? () => history.push(`/${Routes.PatientMedicalInsuranceHub}`, {historyBackSteps: 2})
                : historyBackSteps ? () => history.go(-historyBackSteps) : () => history.goBack()
            } />
          <Text size={32} lineHeight={36} bold>
            {screenTitle || patientId ? 'Guardar Cambios' : 'Añadir Paciente'}
          </Text>
        </View>
        <Container style={{flex: 1}}>
          {loading && <FullScreenLoading text={loadingText}/>}
          <Content
            style={styles.container}
            showsVerticalScrollIndicator={false}
            extraHeight={100}
            extraScrollHeight={100}
            contentContainerStyle={styles.contentContainerStyle}>
            <Text bold={true} marginTop={30}>
              Paciente: {name} {lastname}
            </Text>
            <Text size={13} lineHeight={16} marginTop={24} marginBottom={6}>
              {translations && translations.medicalInsurance
                ? translations.medicalInsurance
                : 'Cobertura Médica'}
            </Text>
            <ButtonChangeCategory
              testID="buttonMedicalInsurance"
              category={medicalInsurance}
              onPress={openMedicalInsuranceModal}
            />

            {medicalInsurance && !medicalInsurance.name.toLowerCase().includes('ioscor') && !medicalInsurance.name.toLowerCase().includes('mpn - medicamentos de primer nivel') && (<InputApp
              testID="inputMedicalInsurancePlan"
              title={
                translations && translations.medicalInsurancePlan
                  ? translations.medicalInsurancePlan
                  : locales_es.medicalInsurancePlan
              }
              placeholder={
                translations && translations.medicalInsurancePlan
                  ? translations.medicalInsurancePlan
                  : locales_es.medicalInsurancePlan
              }
              marginTop={24}
              value={medicalInsurancePlan}
              onChangeText={setMedicalInsurancePlan}
              blurOnSubmit={true}
              autoCapitalize="words"
              returnKeyType="next"
              autoFocus={false}
              onSubmitEditing={() => {
                medicalInsuranceNumberRef.current.focus();
              }}
            />)}
            
            {medicalInsurance && !medicalInsurance.name.toLowerCase().includes('mpn - medicamentos de primer nivel') && (
              <InputApp
                testID="inputMedicalInsuranceNumber"
                forwardRef={medicalInsuranceNumberRef}
                title={
                  translations && translations.medicalInsuranceNumber
                    ? translations.medicalInsuranceNumber
                    : locales_es.medicalInsuranceNumber
                }
                placeholder={
                  translations && translations.medicalInsuranceNumber
                    ? translations.medicalInsuranceNumber
                    : locales_es.medicalInsuranceNumber
                }
                marginTop={24}
                returnKeyType="go"
                value={medicalInsuranceNumber}
                onChangeText={setMedicalInsuranceNumber}
                borderColor={
                  errors.includes('medicalInsuranceNumber')
                    ? theme.lineErrorColor
                    : theme.innearColor
                }
                keyboardType={'numeric'}
              />
            )}
            

            {medicalInsurance && medicalInsurance.name.toLowerCase().includes('ioscor') && !medicalInsurance.name.toLowerCase().includes('mpn - medicamentos de primer nivel') && (<InputApp
              testID="inputMedicalInsuranceDniTitular"
              title={
                translations && translations.medicalInsuranceIdNumber
                  ? translations.medicalInsuranceIdNumber
                  : locales_es.medicalInsuranceIdNumber
              }
              placeholder={
                translations && translations.medicalInsuranceIdNumber
                  ? translations.medicalInsuranceIdNumber
                  : locales_es.medicalInsuranceIdNumber
              }
              marginTop={24}
              value={medicalInsuranceDniTitular}
              onChangeText={setMedicalInsuranceDniTitular}
              blurOnSubmit={true}
              autoCapitalize="words"
              returnKeyType="next"
              autoFocus={false}
              onSubmitEditing={() => {
                onSend();
              }}
            />)}
          </Content>

          <View style={styles.bottomFixedWrapper}>
            <ButtonLinear
              testID="buttonAddPatient"
              white
              title={
                externalIdParam
                  ? 'Guardar en Mis Pacientes'
                  : patientId
                    ? 'Guardar Cambios'
                    : 'Añadir Paciente'
              }
              children={
                <Image
                  source={iconNext}
                  style={styles.buttonChildren}
                />
              }
              disabled={!name && !lastname}
              onPress={onSend}
              style={styles.buttonLinear}
            />
          </View>

          <Modal
            visible={visibleMenuOption}
            onRequestClose={closeMenuOption}
            transparent
            animationType={'fade'}>
            <ModalSelect
              onPressItem={handleSelectIdentificationTypeMenuOption}
              choices={identificationTypesList}
              close={closeMenuOption}
            />
          </Modal>

          <Modal
            visible={visibleCountries}
            onRequestClose={closeCountries}
            transparent
            animationType={'none'}>
            <ModalSlideBottom onClose={closeCountries} transY={transYCountries}>
              <ModalChangePhoneCode
                onChangeCode={onChangeCode}
                countries={countries}
              />
            </ModalSlideBottom>
          </Modal>

          <Modal
            visible={visibleMedicalInsuranceModal}
            onRequestClose={closeMedicalInsuranceModal}
            transparent
            animationType={'none'}>
            <ModalSlideBottom
              onClose={closeMedicalInsuranceModal}
              transY={transYMedicalInsuranceModal}>
              <ModalSearchStrings
                data={medicalInsuranceList}
                onChangeCategory={onChangeMedicalInsurance}
                placeholder={locales_es.search + '...'}
              />
            </ModalSlideBottom>
          </Modal>

        </Container>
        </View>
      </View>
    </>
  );
});

export default PatientMedicalInsurance;

const styles = StyleSheet.create({
  header: {
    ...Theme.flexRowSpace,
  },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  container: {
    ...Theme.flexOne,
  },
  contentContainerStyle: {
    paddingHorizontal: 24,
    // paddingTop: 40,
    paddingBottom: getBottomSpace() + 160,
  },
  buttonChildren: {
    ...Theme.icons,
    marginLeft: 8,
  },
  inputApp: {
    marginTop: 24,
  },
  name: {
    marginTop: scale(38),
  },
  homeAddress: {
    marginTop: 32,
  },
  buttonLinear: {
    // marginTop: 52,
  },
  bottomFixedWrapper: {
    position: 'absolute',
    bottom: 0,
    zIndex: 100,
    width: '100%',
    paddingHorizontal: 24,
    paddingBottom: 52,
    backgroundColor: '#fff',
  },
  genders: {
    marginTop: 24,
    ...Theme.flexRow,
    ...Theme.center,
  },
  phoneView: {
    ...Theme.flexRow,
    marginTop: 4,
  },
  headerWrapper: { flexDirection: "row", gap: 16, alignItems: "center" },
  phoneNumber: {
    marginLeft: 8,
    flex: 1,
  },
});
