import React, { useState, useRef, useEffect } from "react";
import CropModal from "./CropModal";
import { set } from "react-native-reanimated";

interface ImageCropperProps {
  onCropComplete: (croppedImage: string) => void;
  inputLabel?: string;
  setImage?: (image: string) => void;
  image?: any;
  onError?: (error: string) => void;
  disabled?: boolean;
}

const ImageCropper: React.FC<ImageCropperProps> = ({
  onCropComplete,
  inputLabel = "SUBIR IMAGEN",
  setImage,
  image,
  onError,
  disabled = false,
}) => {
  const [selectedImage, setSelectedImage] = useState<string | null>(null);
  const [croppedImageUrl, setCroppedImageUrl] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    const imagUrl = image?.uri;
    setCroppedImageUrl(imagUrl || null);
  }, [image]);

  const handleImageChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files[0];
      if (file.size > 5 * 1024 * 1024) {
        onError &&
          onError(
            "La foto excede el tamaño permitido. Por favor, cargue una imagen de hasta 5MB."
          );
        return;
      }
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const image = reader.result as string;
        setSelectedImage(image);
        setIsModalOpen(true);
        setImage && setImage(image);
      });
      reader.readAsDataURL(file);
    }
  };

  const handleCropComplete = (croppedImage: string) => {
    setCroppedImageUrl(croppedImage);
    setIsModalOpen(false);
    onCropComplete(croppedImage);
  };

  const handleCropCancel = () => {
    setIsModalOpen(false);
    setSelectedImage(null);
    setCroppedImageUrl(null);
    setImage && setImage("");
  };

  const handleDragOver = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
  };

  const handleDrop = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    const files = e.dataTransfer.files;
    if (files && files.length > 0) {
      const file = files[0];
      if (file.size > 5 * 1024 * 1024) {
        onError &&
          onError(
            "La foto excede el tamaño permitido. Por favor, cargue una imagen de hasta 5MB."
          );
      }
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        const image = reader.result as string;
        setSelectedImage(image);
        setIsModalOpen(true);
        setImage && setImage(image);
      });
      reader.readAsDataURL(file);
    }
  };

  const handleUploadClick = () => {
    if (disabled) return;
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
      fileInputRef.current.click();
    }
  };

  return (
    <div style={styles.container}>
      <div
        style={!croppedImageUrl ? styles.uploadArea : styles.selectedImage}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        onClick={!croppedImageUrl ? handleUploadClick : undefined}
      >
        {croppedImageUrl ? (
          <img
            src={croppedImageUrl || ""}
            alt="Imagen recortada"
            style={styles.previewImage}
            onClick={handleUploadClick}
          />
        ) : (
          <>
            <div style={styles.iconContainer}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <rect x="3" y="3" width="18" height="18" rx="2" ry="2" />
                <circle cx="8.5" cy="8.5" r="1.5" />
                <polyline points="21 15 16 10 5 21" />
              </svg>
            </div>
            <span style={styles.uploadText}>{inputLabel}</span>
          </>
        )}
        <input
          ref={fileInputRef}
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={styles.fileInput}
        />
      </div>
      {isModalOpen && selectedImage && (
        <Modal onClose={handleCropCancel}>
          <CropModal
            image={selectedImage}
            onCropComplete={handleCropComplete}
            onCancel={handleCropCancel}
          />
        </Modal>
      )}
    </div>
  );
};

interface ModalProps {
  children: any;
  onClose: () => void;
}

const Modal: React.FC<ModalProps> = ({ children, onClose }) => {
  return (
    <div style={styles.overlay}>
      <div style={styles.modal}>
        <button style={styles.closeButton} onClick={onClose}>
          &times;
        </button>
        {children}
      </div>
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    gap: "12px",
    width: "100%",
    maxWidth: "500px",
    margin: "0 auto",
    zIndex: 1000,
  },
  uploadArea: {
    width: "100%",
    height: "180px",
    border: "2px dashed #ccc",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative" as const,
  },
  selectedImage: {
    width: "100%",
    height: "180px",
    borderRadius: "8px",
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    position: "relative" as const,
  },
  iconContainer: {
    color: "#666",
    marginBottom: "8px",
  },
  uploadText: {
    color: "#666",
    fontSize: "14px",
  },
  fileInput: {
    display: "none",
  },
  helperText: {
    color: "#666",
    fontSize: "14px",
    margin: "0",
  },
  previewImage: {
    maxWidth: "100%",
    maxHeight: "180px",
    objectFit: "contain" as const,
    alignSelf: "start",
    marginRight: "10px",
    borderRadius: "8px",
  },
  overlay: {
    position: "fixed" as const,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: "white",
    opacity: 0.9,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 2000,
  },
  modal: {
    backgroundColor: "white",
    padding: "20px",
    borderRadius: "8px",
    maxWidth: "90%",
    maxHeight: "90%",
    overflow: "auto",
    position: "relative" as const,
  },
  closeButton: {
    position: "absolute" as const,
    top: "10px",
    right: "10px",
    border: "none",
    background: "none",
    fontSize: "24px",
    cursor: "pointer",
  },
};

export default ImageCropper;
