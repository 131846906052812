import React, { useEffect, useState } from "react";
import {
  StyleSheet,
  Modal,
  Image,
  ActivityIndicator,
  View,
  TouchableOpacity,
  ImageBackground,
} from "react-native";
import Text from "../../../components/Text";
import HeaderButton from "../../../components/HeaderButton";
import Theme from "../../../style/Theme";
import scale from "../../../utils/scale";
import InputApp from "../../../components/InputApp";
import ButtonLinear from "../../../components/Buttons/ButtonLinear";
import PrefixSelector from "../../../components/PrefixSelector";
import ModalSelect from "../../../components/ModalSelect";
import locales_es from "../../../locales/es";
import { getStatusBarHeight } from "react-native-iphone-x-helper";
import { useTheme } from "../../../configs/Theme";
import TextInput from "../../../components/TextInput";
import ImgLoadAndCrop from "../../../components/imgLoadAndCrop";
import ImageCropper from "../../../components/ImageCropper/ImageCropper";
import iconNext from "../../../images/ic_next.png";
import { SOURCE_ICON } from "../../../images";
import Stepper from "./stepper";
import useBasicInformationPage from "../shared/useBasicInformationPage";
import ButtonIcon from "../../../components/Buttons/ButtonIcon";
import { Colors } from "../../../configs";
import validateCuitByCountry from "../../../utils/validation/cuit";
import AccountItem from "../../../components/AccountItem/Index";
import { height, width } from "../../../configs/Const";
import RequireIndicator from "../../../components/RequireIndicator";

interface BasicInformationProps {}

const BasicInformationDesktop = (props: BasicInformationProps) => {
  const { theme } = useTheme();
  const {
    profileMode,
    profileImage,
    uploadProfileImage,
    setCropping,
    professionalPrefixId,
    professionalPrefixes,
    onPressPrefixSelectorItem,
    errors,
    identificationType,
    identification,
    identificationRef,
    onBlur,
    showNonEditableInfo,
    openIdentificationTypesMenuOption,
    IdentificationImageError,
    locked,
    setIdentification,
    nameRef,
    name,
    setName,
    setLastname,
    lastname,
    lastnameRef,
    setIdTributario,
    idTributario,
    userData,
    idTributarioRef,
    onSubmit,
    visibleIdentificationTypesMenuOption,
    closeIdentificationTypesMenuOption,
    setIdentificationType,
    identificationTypes,
    cropping,
    enableButton,
    minDocLength,
    maxDocLength,
    countryId,
    showModalSISAInfo,
    SISAVerification,
    setMinDocLength,
    setMaxDocLength,
    setSISAVerification,
    handleSISAVerification,
    handleValidatorInputsTypes,
    errorMessages,
    setIdentificationCropping,
    identificationImage,
    uploadIdentificationImage,
    imgIdentification,
    identificationCropping,
    sisa,
    setSisa,
    setIdentificationImage,
  } = useBasicInformationPage();

  const [validationData, setValidationData] = useState({
    validateCuit: false,
    requestCuit: false,
    requestIdTributary: false,
  });

  useEffect(() => {
    const fetchValidationData = async () => {
      try {
        const data = await validateCuitByCountry();
        setValidationData(data);
      } catch (error) {
        console.error("Error fetching validation data:", error);
      }
    };

    fetchValidationData();
  }, []);

  return (
    <View style={styles.container}>
      <View style={styles.content}>
        {!profileMode && <Stepper />}
        <View style={styles.headerWrapper}>
          <Text size={38} lineHeight={36} bold>
            {locales_es.basicInformation_page.title}
          </Text>
          <Text
            size={18}
            lineHeight={18}
            marginTop={8}
            style={{ maxWidth: "100%" }}
          >
            {locales_es.basicInformation_page.subtitle}
          </Text>
        </View>
        <View style={styles.pageContent}>
          <View style={styles.backgroundImageWrapper}>
            <Image source={SOURCE_ICON.rx} style={styles.backgroundImage} />
          </View>
          <View style={styles.formContainer}>
            {profileImage && (
              <ImgLoadAndCrop
                avatarStyles={styles.avatarStyles}
                containerStyles={styles.imgContainer}
                image={profileImage}
                inputLabel={"Imagen de perfil"}
                imageId={"profileImage"}
                imageState={"profileImage"}
                inputWrapperCustomClassName="col-12 pl-md-0"
                canvasCustomClassName="profile"
                aspect="1"
                saveCrop={uploadProfileImage}
                deleteCrop={() => console.log("deleteCrop")}
                deletedImage={() => console.log("deleteImage")}
                onCropping={() => setCropping(true)}
                onStopCropping={() => setCropping(false)}
              />
            )}
            {!cropping && (
              <View style={styles.form}>
                <View style={styles.prefixField}>
                  <PrefixSelector
                    prefixes={professionalPrefixes}
                    professionalPrefixId={professionalPrefixId}
                    onPressItem={onPressPrefixSelectorItem}
                    style={styles.prefixSelector}
                    containerStyle={
                      errors.includes("professionalPrefixId")
                        ? {
                            borderWidth: 1,
                            borderRadius: 10,
                            borderColor: theme.lineErrorColor,
                          }
                        : null
                    }
                    showRequire={true}
                  />
                  {errors.includes("professionalPrefixId") && (
                    <Text
                      size={13}
                      lineHeight={16}
                      color="red"
                      marginTop={5}
                      marginLeft={5}
                    >
                      {errorMessages.required}
                    </Text>
                  )}
                </View>
                <View style={{ ...styles.formRowEdited }}>
                  <View style={styles.flexGrow}>
                    <InputApp
                      forwardRef={nameRef}
                      marginTop={24}
                      title={locales_es.fullname}
                      value={name}
                      onChangeText={(value: string) => {
                        handleValidatorInputsTypes(
                          value,
                          "letter",
                          setName,
                          "name",
                          2
                        );
                      }}
                      borderColor={
                        errors.includes("name")
                          ? theme.lineErrorColor
                          : theme.innearColor
                      }
                      placeholder={locales_es.fullname}
                      blurOnSubmit={true}
                      onSubmitEditing={() => {
                        lastnameRef.current.focus();
                      }}
                      autoCapitalize="words"
                      returnKeyType="next"
                      autoFocus={false}
                      showRequired={true}
                      maxLength={50}
                    />
                    {errors.includes("name") && (
                      <Text
                        size={13}
                        lineHeight={16}
                        color="red"
                        marginTop={5}
                        marginLeft={5}
                      >
                        {errorMessages.name}
                      </Text>
                    )}
                  </View>
                  <View style={styles.flexGrow}>
                    <InputApp
                      forwardRef={lastnameRef}
                      title={locales_es.lastname}
                      placeholder={locales_es.lastname}
                      value={lastname}
                      marginTop={24}
                      onChangeText={(value: string) => {
                        handleValidatorInputsTypes(
                          value,
                          "letter",
                          setLastname,
                          "lastname",
                          2
                        );
                      }}
                      borderColor={
                        errors.includes("lastname")
                          ? theme.lineErrorColor
                          : theme.innearColor
                      }
                      // onBlur={() => onBlur("lastname")}
                      blurOnSubmit={true}
                      onSubmitEditing={(() => {
                        identificationRef?.current.focus();
                      })}
                      autoCapitalize="words"
                      returnKeyType="go"
                      autoFocus={false}
                      showRequired={true}
                      maxLength={50}
                    />
                    {errors.includes("lastname") && (
                      <Text
                        size={13}
                        lineHeight={16}
                        color="red"
                        marginTop={5}
                        marginLeft={5}
                      >
                        {errorMessages.lastname}
                      </Text>
                    )}
                  </View>
                </View>
                <View style={styles.formRow}>
                  <View style={{ width: "20%" }}>
                    {identificationType ? (
                      <InputApp
                        key="identificationType"
                        title="Tipo de Identificación"
                        marginTop={24}
                        marginBottom={4}
                        value={identificationType.name}
                        editable={false}
                        disabled={locked}
                        onPress={() => {
                          locked
                            ? showNonEditableInfo()
                            : openIdentificationTypesMenuOption();
                        }}
                        borderColor={
                          errors.includes("identificationType")
                            ? theme.lineErrorColor
                            : theme.innearColor
                        }
                        showRequired={true}
                      />
                    ) : (
                      <ActivityIndicator />
                    )}
                  </View>

                  <View style={styles.flexGrow}>
                    <InputApp
                      title={locales_es.identification}
                      onPress={() => {
                        if (locked) {
                          showNonEditableInfo();
                        }
                      }}
                      borderColor={
                        errors.includes("identification")
                          ? theme.lineErrorColor
                          : theme.innearColor
                      }
                      editable={!locked}
                      disabled={locked}
                      forwardRef={identificationRef}
                      value={identification || ""}
                      placeholder={locales_es.identification}
                      onChangeText={(value: string) => {
                        handleValidatorInputsTypes(
                          value,
                          "number",
                          setIdentification,
                          "identification",
                          minDocLength
                        );
                      }}
                      keyboardType="numeric"
                      returnKeyType="go"
                      onSubmitEditing={() => {
                        idTributarioRef.current.focus();
                      }}
                      showRequired={true}
                      maxLength={maxDocLength}
                    />
                  </View>
                  {validationData.requestIdTributary && (
                    <View style={{ ...styles.flexGrow, width: "30%" }}>
                      <InputApp
                        forwardRef={idTributarioRef}
                        title={locales_es.idTributario}
                        placeholder={locales_es.idTributario}
                        value={idTributario}
                        marginTop={24}
                        onChangeText={(value: string) => {
                          handleValidatorInputsTypes(
                            value,
                            "cuit",
                            setIdTributario,
                            "idTributario",
                            11
                          );
                        }}
                        borderColor={
                          errors.includes("idTributario")
                            ? theme.lineErrorColor
                            : theme.innearColor
                        }
                        blurOnSubmit={true}
                        autoCapitalize="words"
                        returnKeyType="go"
                        autoFocus={false}
                        showRequired={validationData.requestCuit}
                        keyboardType="numeric"
                        maxLength={25}
                      />
                    </View>
                  )}
                </View>
                {/* Texto de SISA */}

                <View style={{ ...styles.formRow, marginTop: 5 }}>
                  <View style={{ width: "20%" }}></View>

                  <View style={{ width: "38%" }}>
                    {errors.includes("identification") && (
                      <Text
                        size={13}
                        lineHeight={16}
                        color="red"
                        marginLeft={5}
                      >
                        {errorMessages.identification}
                      </Text>
                    )}
                    <View
                      style={{
                        flexDirection: "row",
                      }}
                    >
                      {!SISAVerification && (
                        <Text
                          size={13}
                          lineHeight={16}
                          color={Colors.Info}
                          marginLeft={5}
                          style={{ width: 430 }}
                        >
                          {locales_es.SISARejected.subtitle}
                        </Text>
                      )}

                      {SISAVerification &&
                        countryId === 1 &&
                        identificationType &&
                        identificationType.id === 1 &&
                        identificationType.name == "DNI" && (
                          <Text
                            size={13}
                            lineHeight={16}
                            marginTop={0}
                            color="gray"
                            marginLeft={5}
                            marginRight={5}
                            onPress={showModalSISAInfo}
                            style={{
                              ...styles.alingButtonSISA,
                              alignSelf: "center",
                            }}
                          >
                            Veficado en el SISA
                            <ButtonIcon
                              icon={"help"}
                              tintColor={Colors.WhiteGray}
                              style={styles.buttonIcon}
                              onPress={showModalSISAInfo}
                              imgStyle={{ width: 30, height: 30 }}
                            />
                          </Text>
                        )}
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "column",
                      alingItems: "start",
                      justifyContent: "start",
                      alingContent: "start",
                      width: "35%",
                      height: 50,
                      zIndex: -10,
                    }}
                  >
                    {errors.includes("idTributario") && (
                      <Text
                        size={13}
                        lineHeight={16}
                        color="red"
                        marginLeft={5}
                      >
                        {errorMessages.idTributario}
                      </Text>
                    )}
                  </View>
                </View>

                <View
                  style={{
                    ...styles.formRowEdited,
                    zIndex: 1000,
                    paddingBottom: 50,
                  }}
                >
                  <View style={{ width: "45%", zIndex: 100, marginTop: 20 }}>
                    <Text size={13} lineHeight={16} color="black" marginTop={5}>
                      Foto del documento <RequireIndicator />
                    </Text>
                    <ImageCropper
                      onError={IdentificationImageError}
                      image={identificationImage}
                      setImage={(e) => setIdentificationImage({ uri: e })}
                      onCropComplete={uploadIdentificationImage}
                      inputLabel="Subir imagen"
                      disabled={
                        userData?.status == "approved" &&
                        identificationImage?.uri
                          ? true
                          : false
                      }
                    />
                    {errors.includes("identificationImage") && (
                      <Text
                        size={13}
                        lineHeight={16}
                        color="red"
                        marginTop={5}
                        marginLeft={5}
                      >
                        {errorMessages.identificationImage}
                      </Text>
                    )}
                    <Text
                      size={13}
                      lineHeight={16}
                      color="black"
                      marginTop={15}
                    >
                      Por favor, subí una imagen de tu documento de identidad.
                      Esto nos permite reforzar la seguridad y evitar la posible
                      suspensión de tu cuenta
                    </Text>
                  </View>
                  <View
                    style={{ width: "65%", zIndex: -10, marginTop: 20 }}
                  ></View>
                </View>

                <View style={styles.cta}>
                  <ButtonLinear
                    white
                    title={profileMode ? "Enviar" : "Continuar"}
                    children={
                      <Image source={iconNext} style={styles.buttonChildren} />
                    }
                    disabled={enableButton}
                    onPress={onSubmit}
                    style={styles.buttonLinear}
                  />
                </View>

                {visibleIdentificationTypesMenuOption ? (
                  <Modal
                    visible={true}
                    onRequestClose={closeIdentificationTypesMenuOption}
                    transparent
                    animationType={"fade"}
                  >
                    <ModalSelect
                      style={{
                        alignSelf: "center",
                        width: "50%",
                      }}
                      onPressItem={(item) => {
                        // onChangeInput(item.value, item);
                        setIdentificationType(item);
                        setMinDocLength(item.min);
                        setMaxDocLength(item.max);
                        if (item.id !== 1 && item.name !== "DNI") {
                          setSISAVerification(false);
                        }
                        setIdentification("");
                      }}
                      choices={identificationTypes}
                      close={closeIdentificationTypesMenuOption}
                    />
                  </Modal>
                ) : null}
              </View>
            )}
          </View>
        </View>
      </View>
    </View>
  );
};

export default BasicInformationDesktop;

const styles = StyleSheet.create({
  buttonChildren: {
    ...Theme.icons,
    marginLeft: 8,
  },
  inputApp: {
    marginTop: 24,
  },
  name: {
    marginTop: scale(38),
  },
  homeAddress: {
    marginTop: 32,
  },
  buttonLinear: {
    marginTop: 13,
  },
  genders: {
    marginTop: 24,
    ...Theme.flexRow,
    ...Theme.center,
  },
  buttonIcon: {
    width: 50,
    height: 50,
    borderRadius: 50,
    backgroundColor: "#fafafa",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: 0,
    marginTop: 0,
    paddingLeft: 16,
  },
  //nuevo
  imgContainer: { minWidth: 380, width: "70%", marginTop: 0 },
  avatarStyles: { width: 150, height: 150, marginRight: 16 },
  container: { flex: 1, minHeight: "100%" },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  headerWrapper: {
    flexDirection: "column",
    alignItems: "flex-start",
    maxWidth: "100%",
    marginTop: 32,
  },
  pageContent: {
    flexDirection: "row-reverse",
    flexWrap: "wrap",
    justifyContent: "flex-end",
    marginTop: 32,
  },
  backgroundImageWrapper: {
    width: "40%",
    minWidth: 400,
    alignItems: "flex-start",
    marginBottom: 32,
  },
  backgroundImage: { width: 420, height: 380 },
  formContainer: { flex: 1, minWidth: 400 },
  form: { marginTop: 24 },
  prefixField: { width: 200 },
  prefixSelector: {
    padding: 0,
    marginTop: 0,
  },
  imgUpload: {
    border: "2px dashed grey",
    borderRadius: 16,
    marginTop: 16,
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: 200,
  },
  formRow: {
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "flex-end",
    gap: 16,
  },
  image: {
    justifyContent: "center",
    width: "100%",
    height: "100%",
  },
  formRowEdited: {
    flexDirection: "row",
    justifyContent: "flex-start",
    zIndex: 10,
    gap: 16,
  },
  cta: { width: 200 },
  flexGrow: { flex: 1 },
  alingButtonSISA: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  identificationImage: {
    marginTop: 4,
    minHeight: 150,
    borderRadius: 8,
    backgroundColor: "#fff",
  },
  switch: {
    // ...Theme.flexRowSpace,
    paddingTop: 12,
    paddingBottom: 12,
    paddingRight: 20,
    margin: 0,
    display: "flex",
    flexDirection: "row",
    gap: 80,
  },
});
