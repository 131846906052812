import React, {
  memo,
  useCallback,
  useState,
  useEffect,
} from 'react';
import {
  View,
  StyleSheet,
  FlatList,
  Share,
  Alert,
  Animated,
  ActivityIndicator,
} from 'react-native';
import Text from '../../components/Text';
import Theme from '../../style/Theme';
import {Routes} from '../../configs';
import Container from '../../components/Layout/Container';
import Layout from '../../components/Layout/Layout';
import HeaderButton from '../../components/HeaderButton';
import {getBottomSpace} from 'react-native-iphone-x-helper';
import keyExtractor from '../../utils/keyExtractor';
import {FEATURE_ICON} from '../../images/MyWork';
import FeatureItem from '../../components/FeatureItem';

import {api, helpers} from '../../import';
import locales_es from '../../locales/es';
import {showModalInfo} from '../../modules/ViewHelpers';
import {CommonStyles} from '../../styles/CommonStyles';
import CountryService from '../../modules/CountryService';
import {PRESCRIPTION_ERROR_INVALID_CREDENTIAL} from '../../models/constants';
import {useHistory} from "react-router-dom";
import {RWebShare} from "react-web-share";
import { getStatusBarHeight } from "react-native-iphone-x-helper";

interface PatientMedicalInsuranceHubProps {}

const PatientMedicalInsuranceHub = memo(
  (props: PatientMedicalInsuranceHubProps) => {
    const history = useHistory();
    const params = history.location.state;
    const historyBackSteps = params && params.historyBackSteps ? params.historyBackSteps : null;
    const prescriptionData = params && params.prescriptionData ? params.prescriptionData : null;
    const patientId = params && params.patientId ? params.patientId : null;
    const [fadeAnim] = useState(new Animated.Value(0));
    const [positionAnim] = useState(new Animated.Value(-50));
    const [enabledScan, setEnabledScan] = useState(false);
    const [availableOptions, setAvailableOptions] = useState([]);
    const [entityUpdateWebLink, setEntityUpdateWebLink] = useState(null)

    const renderFeature = (item, index) => {
      return (
        <span className="cursor-pointer" style={{
            flex: 0.5, 
            justifyContent: 'center',
            marginTop: 10,
            width: '100%',
          }}><FeatureItem
          sharer={item.sharer}
          style={[index % 2 === 0 ? {marginRight: 16} : null, {paddingTop: 10, paddingBottom: 10}]}
          imgStyle={{padding: 20, width: '60%'}}
          {...item}
        />
      </span>
      )
    }

    const renderFeatureItem = useCallback(({item, index}) => {
      if (item.sharer) {
        return <RWebShare
          data={{
            title: locales_es.updatePatientFormInfoTitle,
            text: locales_es.updatePatientFormInfoText,
            url: entityUpdateWebLink,
          }}
          onClick={() => console.log("shared successfully!")}
        >
          {renderFeature(item, index)}
        </RWebShare>
      } else {
        return renderFeature(item, index)
      }
    }, [entityUpdateWebLink]);

    const animateQRInfo = () => {
      Animated.timing(fadeAnim, {
        toValue: 1,
        duration: 500,
      }).start();
      Animated.timing(positionAnim, {
        toValue: 0,
        duration: 1000,
      }).start();
    };

    useEffect(() => {
      api
        .getPatientSharerUpdaterLink(
          patientId,
          PRESCRIPTION_ERROR_INVALID_CREDENTIAL,
        )
        .then((res: any) => {
          setEntityUpdateWebLink(res.data.url);
        })
        .catch((err: any) => {
          helpers.processError(err);
        });
      const defaultOptions = [
        {
          id: 4,
          img: FEATURE_ICON.careTeam,
          title: 'Revisar los datos de la cobertura médica',
          route: Routes.PatientMedicalInsurance,
          params: {patientId, prescriptionData,historyBackSteps},
        },
        {
          id: 7,
          img: FEATURE_ICON.workProfile,
          title: 'Invita al paciente a que revise sus datos de cobertura',
          // onPress: onShare,
          sharer: true,
        },
      ];
      setAvailableOptions(defaultOptions);
    }, []);

    const goBack = () => {
      history.replace(`/${Routes.AddPrescription}`, {patientId: patientId, prevPrescriptionData: prescriptionData, historyBackSteps})
    };

    return (
      <>
        <View style={styles.headerWrapper}>
          <HeaderButton onPress={goBack} />
          <Text size={32} lineHeight={36} bold>
            Revisar Paciente
          </Text>
        </View>
        <Container style={styles.container}>
          <Text
            marginTop={24}
            marginHorizontal={24}
            bold
            size={24}
            lineHeight={28}>
            ¿Cómo deseas revisarlo?
          </Text>
          <View style={[styles.topView, Theme.flexRowSpace]}>
            <Text size={15} lineHeight={18}>
              Puedes corregir los datos de la cobertura, o enviarle un enlace al
              paciente para que los revise por cuenta propia.
            </Text>
          </View>
          <View style={styles.itemContainer}>
            {availableOptions.length ? (
              <FlatList
                data={availableOptions}
                renderItem={renderFeatureItem}
                keyExtractor={keyExtractor}
                numColumns={2}
                scrollEventThrottle={16}
                contentContainerStyle={styles.contentContainerStyle}
                showsVerticalScrollIndicator={false}
              />
            ) : (
              <ActivityIndicator />
            )}
          </View>
        </Container>
      </>
    );
  },
);

export default PatientMedicalInsuranceHub;

const styles = StyleSheet.create({
  container: {
    flex: 1,
  },
  topView: {
    marginTop: 12,
    marginBottom: 12,
    paddingHorizontal: 24,
  },
  headerWrapper: { flexDirection: "row", gap: 16, alignItems: "center" },
  content: {
    minHeight: "100%",
    paddingBottom: 24,
    paddingLeft: 24,
    paddingRight: 24,
    paddingTop: getStatusBarHeight(),
  },
  contentContainerStyle: {
    paddingHorizontal: 24,
    paddingTop: 30,
    paddingBottom: getBottomSpace(),
  },
  itemContainer: {
    width: 600
  }
});
