import React from "react";
import { useState, useRef, useEffect } from "react";

interface CropModalProps {
  image: string;
  onCropComplete: (croppedImage: string) => void;
  onCancel: () => void;
}

const CropModal: React.FC<CropModalProps> = ({
  image,
  onCropComplete,
  onCancel,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0, width: 320, height: 200 });
  const [isDragging, setIsDragging] = useState(false);
  const [isResizing, setIsResizing] = useState(false);
  const [activeHandle, setActiveHandle] = useState<string | null>(null);
  const [dragStart, setDragStart] = useState({ x: 0, y: 0 });
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const imageRef = useRef<HTMLImageElement>(null);
  const cropBoxRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      if (imageRef.current) {
        const aspectRatio = img.width / img.height;
        const containerWidth = imageRef.current.offsetWidth;
        const containerHeight = imageRef.current.offsetHeight;
        let newWidth, newHeight;

        if (containerWidth / containerHeight > aspectRatio) {
          newHeight = containerHeight;
          newWidth = newHeight * aspectRatio;
        } else {
          newWidth = containerWidth;
          newHeight = newWidth / aspectRatio;
        }

        setCrop({
          x: (containerWidth - newWidth) / 2,
          y: (containerHeight - newHeight) / 2,
          width: Math.min(320, newWidth * 0.8),
          height: Math.min(200, newHeight * 0.8),
        });
      }
    };
    img.src = image;
  }, [image]);

  const getCroppedImage = () => {
    if (!imageRef.current || !canvasRef.current) return null;

    const canvas = canvasRef.current;
    const img = imageRef.current;
    const scaleX = img.naturalWidth / img.width;
    const scaleY = img.naturalHeight / img.height;

    canvas.width = crop.width;
    canvas.height = crop.height;

    const ctx = canvas.getContext("2d");
    if (!ctx) return null;

    ctx.drawImage(
      img,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    return canvas.toDataURL("image/jpeg", 0.92);
  };

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === cropBoxRef.current) {
      setIsDragging(true);
      setDragStart({ x: e.clientX - crop.x, y: e.clientY - crop.y });
    } else if ((e.target as HTMLElement).classList.contains("resize-handle")) {
      setIsResizing(true);
      setActiveHandle((e.target as HTMLElement).dataset.handle || null);
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseMove = (e: React.MouseEvent<HTMLDivElement>) => {
    if (isDragging && imageRef.current) {
      const rect = imageRef.current.getBoundingClientRect();
      let newX = e.clientX - dragStart.x;
      let newY = e.clientY - dragStart.y;

      newX = Math.max(0, Math.min(newX, rect.width - crop.width));
      newY = Math.max(0, Math.min(newY, rect.height - crop.height));

      setCrop((prev) => ({ ...prev, x: newX, y: newY }));
    } else if (isResizing && imageRef.current && activeHandle) {
      const rect = imageRef.current.getBoundingClientRect();
      const deltaX = e.clientX - dragStart.x;
      const deltaY = e.clientY - dragStart.y;

      const newCrop = { ...crop };

      switch (activeHandle) {
        case "topLeft":
          newCrop.width = Math.max(100, crop.width - deltaX);
          newCrop.height = Math.max(100, crop.height - deltaY);
          newCrop.x = Math.min(crop.x + deltaX, crop.x + crop.width - 100);
          newCrop.y = Math.min(crop.y + deltaY, crop.y + crop.height - 100);
          break;
        case "topRight":
          newCrop.width = Math.max(100, crop.width + deltaX);
          newCrop.height = Math.max(100, crop.height - deltaY);
          newCrop.y = Math.min(crop.y + deltaY, crop.y + crop.height - 100);
          break;
        case "bottomLeft":
          newCrop.width = Math.max(100, crop.width - deltaX);
          newCrop.height = Math.max(100, crop.height + deltaY);
          newCrop.x = Math.min(crop.x + deltaX, crop.x + crop.width - 100);
          break;
        case "bottomRight":
          newCrop.width = Math.max(100, crop.width + deltaX);
          newCrop.height = Math.max(100, crop.height + deltaY);
          break;
      }

      if (newCrop.x < 0) {
        newCrop.width += newCrop.x;
        newCrop.x = 0;
      }
      if (newCrop.y < 0) {
        newCrop.height += newCrop.y;
        newCrop.y = 0;
      }
      if (newCrop.x + newCrop.width > rect.width) {
        newCrop.width = rect.width - newCrop.x;
      }
      if (newCrop.y + newCrop.height > rect.height) {
        newCrop.height = rect.height - newCrop.y;
      }

      setCrop(newCrop);
      setDragStart({ x: e.clientX, y: e.clientY });
    }
  };

  const handleMouseUp = () => {
    setIsDragging(false);
    setIsResizing(false);
    setActiveHandle(null);
  };

  const handleConfirmCrop = () => {
    const croppedImage = getCroppedImage();
    if (croppedImage) {
      onCropComplete(croppedImage);
    }
  };

  return (
    <div style={styles.cropperContainer}>
      <div
        style={styles.imageContainer}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseUp}
      >
        <img
          ref={imageRef}
          src={image || "/placeholder.svg"}
          alt="Imagen para recortar"
          style={styles.image}
        />
        <div
          ref={cropBoxRef}
          style={{
            ...styles.cropBox,
            left: crop.x,
            top: crop.y,
            width: crop.width,
            height: crop.height,
          }}
        >
          <div
            className="resize-handle"
            data-handle="topLeft"
            style={{ ...styles.resizeHandle, top: -5, left: -5 }}
          ></div>
          <div
            className="resize-handle"
            data-handle="topRight"
            style={{ ...styles.resizeHandle, top: -5, right: -5 }}
          ></div>
          <div
            className="resize-handle"
            data-handle="bottomLeft"
            style={{ ...styles.resizeHandle, bottom: -5, left: -5 }}
          ></div>
          <div
            className="resize-handle"
            data-handle="bottomRight"
            style={{ ...styles.resizeHandle, bottom: -5, right: -5 }}
          ></div>
        </div>
      </div>
      <div className="btn-container" style={styles.buttonContainer}>
        <button onClick={handleConfirmCrop} className="btn btn-primary">
          Aceptar
        </button>
        <button onClick={onCancel} className="btn btn-secondary ml-4">
          Cancelar
        </button>
      </div>
      <canvas ref={canvasRef} style={styles.hiddenCanvas} />
    </div>
  );
};

const styles = {
  cropperContainer: {
    display: "flex",
    flexDirection: "column" as const,
    alignItems: "center",
    gap: "20px",
    zIndex: 2000,
    backgroundColor: "white",
  },
  imageContainer: {
    position: "relative" as const,
    width: "100%",
    height: "400px",
    overflow: "hidden",
  },
  image: {
    maxWidth: "100%",
    maxHeight: "100%",
    objectFit: "contain" as const,
  },
  cropBox: {
    position: "absolute" as const,
    border: "2px solid white",
    boxShadow: "0 0 0 9999em rgba(0, 0, 0, 0.5)",
    cursor: "move",
  },
  resizeHandle: {
    position: "absolute" as const,
    width: "10px",
    height: "10px",
    background: "white",
    border: "1px solid #333",
    cursor: "nwse-resize",
    borderRadius: "50%",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    gap: "10px",
  },
  button: {
    padding: "8px 20px",
    backgroundColor: "#007bff",
    color: "white",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  hiddenCanvas: {
    display: "none",
  },
};

export default CropModal;
