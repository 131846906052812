import locales_es from "./../locales/es";
import { Routes } from "../configs";

export const userDataLocalStorage = "user_data";
export const clinicDataLocalStorage = "clinic_data";
export const clinicDataExpireDateLocalStorage = "clinic_data_expire_date";
export const countryLS = "country_ls";
export const prescriptionId = "prescription_id";
export const userTemporalDataPostRegister = "user_temporal_data_post_register";
export const hubspotTokenLabel = "hubspot_token";
export const hubspotTokenDateLabel = "hubspot_token_date";

// TIMEZONE
export const DEFAULT_TIME_ZONE = "America/Argentina/Buenos_Aires";

// STORAGE & USER CONSTANTS
export const userAccessToken = "token";

// HREF
export const HREF_PAGE_HOME = "/";
export const HREF_PAGE_PATIENTS = "/patients";
export const HREF_PAGE_DASHBOARD = "/dashboard";
export const HREF_PAGE_LOGIN = "/login";
export const hrefRegister = "/register";
export const hrefRecovery = "/recovery";
export const hrefDashboard = "/dashboard";
export const HREF_PAGE_CHANGE_PASSWORD = "/change-password";
export const HREF_PAGE_MY_PROFILE = "/my-profile";
export const HREF_PAGE_TERMS = "/terms";

export const CLASSNAME_INVALID_INPUT_FIELD = "invalid-input-field";
export const CLASSNAME_VALID_INPUT_FIELD = "valid-input-field";
// parseTelInput
//export const PARSE_TEL_INPUT_ONLY_COUNTRIES = ['AR', 'BR', 'EC', 'CU', 'MX', 'UY', 'CL', 'PY', 'CO', 'VE', 'PE'];
export const PARSE_TEL_INPUT_ONLY_COUNTRIES = ["AR", "PY", "EC"];
export const PARSE_TEL_INPUT_COUNTRIES_CODES = [
  {
    countryCode: "ar",
    dialCode: "+54",
  },
  {
    countryCode: "br",
    dialCode: "+55",
  },
  {
    countryCode: "ec",
    dialCode: "+593",
  },
  {
    countryCode: "cu",
    dialCode: "+53",
  },
  {
    countryCode: "mx",
    dialCode: "+52",
  },
  {
    countryCode: "uy",
    dialCode: "+598",
  },
  {
    countryCode: "cl",
    dialCode: "+56",
  },
  {
    countryCode: "py",
    dialCode: "+592",
  },
  {
    countryCode: "co",
    dialCode: "+57",
  },
  {
    countryCode: "ve",
    dialCode: "+58",
  },
  {
    countryCode: "pe",
    dialCode: "+51",
  },
];

// API ERROR CODES
export const emailExists = "email_exists";

// USER TYPES
export const USER_TYPE_PATIENT = "patient";
export const USER_TYPE_MEDIC = "medic";
export const USER_TYPE_SECRETARY = "secretary";

// USER STATUS
export const USER_STATUS_PENDING = "pending";
export const USER_STATUS_APPROVED = "approved";
export const USER_STATUS_REJECTED = "rejected";
export const USER_STATUS_BANNED = "banned";

// FULLCALENDAR
export const FC_SLOT_MIN_TIME = "08:00";
export const FC_SLOT_MAX_TIME = "21:00";

// HEADER
export const headerNavId = "headerNav";
export const headerNavLogoutLinkId = "headerNavLogoutLink";
export const headerNavWrapperId = "headerNavWrapper";
export const setNavLinksEvent = "setNavLinks";
export const setHiddenHeaderEvent = "setHiddenHeader";

export const STATUS_COLORS = {
  appointmentBadges: {
    scheduled: "kt-badge--primary",
    pending: "kt-badge--primary",
    confirmed: "kt-badge--success",
    waiting_room: "kt-badge--accent",
    attending: "kt-badge--focus",
    completed: "kt-badge--dark",
    patient_missed: "kt-badge--danger",
    patient_canceled: "kt-badge--metal",
    medic_canceled: "kt-badge--metal",
  },
  appointmentButton: {
    scheduled: "btn-primary",
    pending: "btn-primary",
    confirmed: "btn-success",
    waiting_room: "btn-accent",
    attending: "btn-focus",
    completed: "btn-dark",
    patient_missed: "btn-danger",
    patient_canceled: "btn-metal",
    medic_canceled: "btn-metal",
  },
  fancyDotSpinner: {
    scheduled: "text-primary",
    pending: "text-primary",
    confirmed: "text-success",
    waiting_room: "text-accent",
    attending: "text-focus",
    completed: "text-dark",
    patient_missed: "text-danger",
    patient_canceled: "text-metal",
    medic_canceled: "text-metal",
  },
  calendarDot: {
    scheduled: "dot-primary",
    pending: "dot-primary",
    confirmed: "dot-success",
    waiting_room: "dot-accent",
    attending: "dot-focus",
    completed: "dot-dark",
    patient_missed: "dot-danger",
    patient_canceled: "dot-metal",
    medic_canceled: "dot-metal",
  },
};

// From RCTA APP
export const coachmarkLS = "coachmark_ls";
export const MED_PRESCRIPTION_REQ_SERVICE_TAG = "medical_prescription_request";
export const CLINIC_PATIENTS_SERVICE_TAG = "clinic_patients";
export const MED_CERTIFICATES_TAG = "medical_certificates";

export const PRESCRIPTION_STYLE_FREE = "free";
export const PRESCRIPTION_STYLE_PRACTICES = "practices";

export const DARK_MODE_TAG = "dark";
export const LIGHT_MODE_TAG = "light";

export const FAKE_EMAIL_REGEX = /\@rcta\.me/;
export const CONTACT_SUPPORT_LINK = "mailto:soporte@rcta.me?subject=Consulta";
export const CONTACT_SUPPORT_LINK_EC =
  "mailto:soporteec@rcta.me?subject=Consulta";
export const WEBSITE_URL = "https://rcta.me";

export const LS_LOCAL_AUTH = "localAuthLS";

export const configDataLocalStorage = "config_data";

// PRESCRIPTIONS REQUESTS STATUS
export const PRESCRIPTION_REQUEST_STATUS_CREATED = "created";
export const PRESCRIPTION_REQUEST_STATUS_ACCEPTED = "accepted";
export const PRESCRIPTION_REQUEST_STATUS_IGNORED = "ignored";
export const PRESCRIPTION_REQUEST_STATUS_REJECTED = "rejected";

// PRESCRIPTIONS REQUESTS ERROR CASES
export const PRESCRIPTION_ERROR_INVALID_CREDENTIAL = "invalid_credential";
export const PRESCRIPTION_ERROR_INVALID_CREDENTIAL_QBI172 = "invalid_credential_qbi172";
export const PRESCRIPTION_ERROR_INVALID_TAX_ID = "invalid_tax_id";
export const PRESCRIPTION_ERROR_INVALID_FINANCIADOR = "invalid_financiador";

// PRESCRIPTIONS PRESCRIPTION MODES
export const PRESCRIPTION_MODE_NO_MEDICAL_INSURANCE = "no_medical_insurance";

export const DYNAMIC_TEXT_REGEX = /\$\{x\}/;

export const MAX_CERTIFICATE_PRESCRIPTIONS = 10;
export const VADEMECUM_TYPE_QBI2 = "qbi2";
export const PATIENT_VALIDATE_FIELD_IDENTIFICATION = "identification";
export const FEATURED_ROUTES = [
  {
    title: locales_es.menu.myPatients,
    route: Routes.MyPatients,
    icon: "patient",
  },
  {
    title: locales_es.menu.configuration,
    route: Routes.Configuration,
    icon: "setting2",
  },
];

export const INSURANCE_PERMISSION = "medic-prescription-insurance";
export const MEDICINES_PERMISSION = "medic-prescription-medicines";
export const PRACTICES_PERMISSION = "medic-prescription-practices";

export const DESKTOP_ROUTES = [
  Routes.Home,
  Routes.Configuration,
  Routes.BasicInformation,
  Routes.PatientProfile,
  Routes.WorkProfile,
  Routes.OtherInformation,
  Routes.PaymentInformation,
  Routes.PatientPrescriptions,
  Routes.MyPatients,
  Routes.AddPatient,
  Routes.DocumentVisor,
  Routes.AddPatientHub,
  Routes.CreatingYourFirstPrescription,
  Routes.PatientCertificates,
  Routes.AddCertificate,
  Routes.AddPrescription,
  Routes.SendDocuments,
  Routes.ChangePassword,
  Routes.P,
  Routes.PatientMedicalInsuranceHub,
  Routes.PatientMedicalInsurance,
  Routes.About,
  Routes.AddMedicalLicense,
];
